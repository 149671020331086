<template>
  <div class="wrap">
    <Navbar :title="$t('user.reportCenter')" />
    <div class="content">
      <div class="userInfo">
        <div class="userInfo_top">
          <Avatar size="40" :src="userInfo.userimg" :defaultSrc="require('@tt/assets/defaultAvatar/A6405.png')" />
          <div class="name">{{ userInfo.username }}</div>
        </div>
        <div class="userInfo_amount">
          <div class="amount">
            <div class="title">{{ $t('reportCenter.balance') }}</div>
            <div class="value">{{ userInfo.money || 0 }}</div>
          </div>
          <div class="amount right_amount">
            <div class="title">{{ $t('reportCenter.profitAndLoss') }}</div>
            <div class="value">{{ (Number(userInfo.betwin) - Number(userInfo.betmoney)) || 0 }}</div>
          </div>
        </div>
      </div>
      <div class="list">
        <div class="list_item">
          <div class="item_top">
            <img class="img" src="../../assets/new/user/zjje.svg" alt="">
            <div class="item_title">{{ $t('reportCenter.winningAmount') }}</div>
          </div>
          <div class="item_money">{{ userInfo.betmoney || 0 }}</div>
        </div>
        <div class="list_item">
          <div class="item_top">
            <img class="img" src="../../assets/new/user/tzje.svg" alt="">
            <div class="item_title">{{ $t('reportCenter.bettingAmount') }}</div>
          </div>
          <div class="item_money">{{ userInfo.betwin || 0 }}</div>
        </div>
        <div class="list_item">
          <div class="item_top">
            <img class="img" src="../../assets/new/user/cz.svg" alt="">
            <div class="item_title">{{ $t('My.MemberInformation.Recharge') }}</div>
          </div>
          <div class="item_money">{{ userInfo.money_plus || 0 }}</div>
        </div>
        <div class="list_item">
          <div class="item_top">
            <img class="img" src="../../assets/new/user/tx.svg" alt="">
            <div class="item_title">{{ $t('My.MemberInformation.Withdraw') }}</div>
          </div>
          <div class="item_money">{{ userInfo.money_extract || 0 }}</div>
        </div>
      </div>
      <div class="content_btns">
        <div class="btn_item" @click="toRecharge">
          <img :src="require('../../assets/new/user/recharge.svg')" alt="">
          <div class="text">{{ $t("My.MemberInformation.Recharge") }}</div>
        </div>
        <div class="btn_item withdraw" @click="toWithdraw" color="">
          <img :src="require('../../assets/new/user/withdraw.svg')" alt="">
          <div class="text">{{ $t("My.MemberInformation.Withdraw") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from "@/components/JNav";
import { Avatar } from "@tt/components";
import { Toast } from 'vant';
export default {
  components: {
    Navbar,
    Avatar
  },
  data() {
    return {
      userInfo: {}
    }
  },
  created() {
    this.getUserInfo()
  },
  methods: {
    toRecharge() {
      return this.$store.dispatch("toService");
      this.$router.push({ name: "NewRecharge" });
    },
    toWithdraw() {
      this.$router.push({ name: "newWithdraw" });
    },
    getUserInfo() {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      this.$api.getUserInfo(
        (res) => {
          Toast.clear();
          this.userInfo = res
        },
        (err) => {
          Toast.clear();
        }
      );
    },
  }
}
</script>
<style lang="less" scoped>
.wrap {
  min-height: 100vh;

  .content {
    padding: calc(20rem / 16);

    .userInfo {
      background-image: url(../../assets/new/user/top_bg.svg), linear-gradient(178deg, #13a2ba, #087c95);
      background-position: 100%;
      background-size: auto 100%;
      min-height: calc(152rem / 16);
      border-radius: calc(10rem / 16);
      overflow: hidden;

      &_top {
        background-color: hsla(0, 0%, 100%, .2);
        display: flex;
        align-items: center;
        padding: calc(11rem / 16) calc(15rem / 16);

        .name {
          margin-left: calc(10rem / 16);
          color: var(--light);
          font-size: calc(12rem / 16);
        }
      }

      &_amount {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: calc(14rem / 16) calc(25rem / 16) calc(19rem / 16);

        .amount {
          flex: 0 0 50%;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;

          .title {
            color: hsla(0, 0%, 100%, .5);
            font-size: calc(18rem / 16);
          }

          .value {
            color: var(--light);
            word-break: break-word;
            font-size: calc(30rem / 16);
            margin-top: calc(10rem / 16);
          }
        }

        .right_amount {
          text-align: right;
        }
      }
    }

    .list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: calc(10rem / 16);

      &_item {
        flex: 0 0 47%;
        background-color: #132235;
        border-radius: calc(10rem / 16);
        margin-bottom: calc(10rem / 16);
        padding: calc(17rem / 16);
        box-sizing: border-box;
        overflow-wrap: break-word;

        .item_top {
          display: flex;
          align-items: center;
          justify-content: center;

          .img {
            width: calc(20rem / 16);
            height: calc(20rem / 16);
            display: block;
          }

          .item_title {
            margin-left: calc(10rem / 16);
            color: var(--light);
            font-size: calc(16rem / 16);
          }
        }

        .item_money {
          color: var(--light);
          margin-top: calc(6rem / 16);
          text-align: center;
          font-size: calc(24rem / 16);
          word-break: break-word;
        }
      }
    }

    &_btns {
      margin-top: calc(20rem / 16);
      display: flex;

      .btn_item {
        flex: 1;
        border-radius: calc(10rem / 16);
        min-height: calc(44rem / 16);
        color: var(--light);
        font-size: calc(18rem / 16);
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: linear-gradient(179deg, #13a2ba, #087c95);

        img {
          width: calc(20rem / 16);
          display: block;
          margin-right: calc(10rem / 16);
        }
      }

      .withdraw {
        margin-left: calc(20rem / 16);
        .btn_item();
        background-image: linear-gradient(124deg, #efdaaf, #dbb579);
      }
    }
  }
}
</style>